//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-556:_8212,_3972,_4980,_4581,_5080,_2648,_3084,_7028;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-556')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-556', "_8212,_3972,_4980,_4581,_5080,_2648,_3084,_7028");
        }
      }catch (ex) {
        console.error(ex);
      }